import emailjs from 'emailjs-com';

const sendEmail = (order_id, client_id, name, phone, auto, budget, country, city, status_buy) => {
    const templateParams = {
        order_id: order_id,
        salebot_client_id: client_id,
        name: name,
        phone: phone,
        auto: auto,
        budget: budget,
        country: country,
        city: city,
        status_buy: status_buy
    };
    console.log(client_id)
    emailjs.send('service_uztfbjk', 'template_6u6sb8r', templateParams, '_MMWQ4HN8oebMerE3')
    // emailjs.send('service_z8hqqwl', 'template_5ey7278', templateParams, 'ADz_mtHsdROvA68R9')
        .then((response) => {
            console.log('Письмо успешно отправлено!', response.status, response.text);
        })
        .catch((err) => {
            console.error('Ошибка при отправке письма:', err);
        });
};

export default sendEmail;