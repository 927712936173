const setOrderVars = async (token, clientId, orderId, variables) => {
    const url = `https://chatter.salebot.pro/api/${token}/set_order_vars`;
    console.log("orderID", orderId)
    const bodyData = {
        client_id: clientId,
        order_id: orderId,
        variables: variables
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            // mode: 'no-cors',
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            body: JSON.stringify(bodyData),
        });

        return await response.json();
    } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
    }
};


export default setOrderVars;