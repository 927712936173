const sendLead = async (clientId, token, countLead) => {
    const params = {
        client_id: clientId,
        variables: {"client.lead": countLead},
    };
    const url = `https://chatter.salebot.pro/api/${token}/save_variables`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
        });
        if (response.ok) {
            return await response;
        }

    } catch (error) {
        console.error("Ошибка:", error);
        return false
    }
};


export default sendLead;
