import React, {useEffect} from 'react';
import './App.css';
import BudgetForm from "./BudgetForm"; // Подключаем стили

function App() {
    useEffect(() => {
        const telegram = window.Telegram.WebApp;
        telegram.setBackgroundColor("#ffffff"); // Вы можете изменить этот цвет на любой другой
    }, []);

    return (
        <BudgetForm/>

    );
}

export default App;
