const sendCallback = async (userId, token) => {
    const url = `https://chatter.salebot.pro/api/${token}/send_callback_by_platform_id
`;
    const bodyData = {
        platform_ids: [userId],
        callback_text: "webapp_success"
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(bodyData),
        });
        if (response.ok){
            return await response.json();
        }

    } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
        return false;
    }
};

export default sendCallback;