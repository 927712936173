const getClient = async (userId, token) => {
    const url = `https://chatter.salebot.pro/api/${token}/find_client_id_by_platform_id`;
    const bodyData = {
        platform_ids: [userId], // Укажите массив идентификаторов
        group_id: "likeavto_sales_bot",                // Укажите идентификатор бота
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(bodyData),
        });
        const data = await response.json();
        const clientId = data[0]["id"]
        const countLead = data[0]["variables"]["lead"]
        return {
            clientId: clientId,
            countLead: countLead,
            formCounter: data[0]["variables"]["form_counter"]
        };

    } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
        return false;
    }
};

export default getClient;