import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import sendLead from "./SendLead";
import getClient from "./GetClient";
import createOrder from "./CreateOrder";
import setOrderVars from "./SetOrderVars";
import sendEmail from "./SendEmail";
import Swal from 'sweetalert2';
import sendFormCounter from "./SendFormCounter";
import logo from './logo.jpg';
import sendCallback from "./SendCallback";

const BudgetForm = () => {
    const [name, setName] = useState('');
    const [budget, setBudget] = useState('');
    const [readyToBuy, setReadyToBuy] = useState('Готов к покупке');
    const [country, setCountry] = useState('Корея');
    const [brand, setBrand] = useState('');
    const [budgetAmount, setBudgetAmount] = useState('7500000');
    const [city, setCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState("RU");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [clientId, setClientId] = useState(0);
    const [countLead, setCountLead] = useState(0);
    const [errors, setErrors] = useState({});
    const [maxPhoneLength, setMaxPhoneLength] = useState(10); // Установка начального значения для maxLength

    const token = '9087f7b987eb5b9c1f9a58622b653b42';
    const validate = async () => {
        let errors = {};
        if (!name) {
            errors.name = 'Имя обязательно';
        }
        if (!budget) {
            errors.budget = 'Бюджет обязателен';
        }
        if (phoneNumber.length < maxPhoneLength) {
            errors.phoneNumber = 'Номер телефона введен не корректно';
        }
        if (!phoneNumber) {
            errors.phoneNumber = 'Номер телефона обязателен';
        }
        if (budget === "До 7 500 000") {
            if (!brand) {
                errors.brand = 'Марка и модель авто обязательна';
            }
            if (!budgetAmount) {
                errors.budgetAmount = 'Бюджет авто обязателен';
            }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;

    };
    const countries = [
        {code: "RU", name: "Russia (Россия)", dialCode: "+7", placeholder: "(912) 345-67-89", maxLength: 10},
        {code: "BY", name: "Belarus (Беларусь)", dialCode: "+375", placeholder: "09812345678", maxLength: 11},
        {code: "KZ", name: "Kazakhstan (Қазақстан)", dialCode: "+7", placeholder: "(812) 345-67-89", maxLength: 10}
    ];
    const countryPlaceholders = {
        RU: "(912) 345-67-89",
        BY: "09812345678",
        KZ: "(812) 345-67-89",
    };

    useEffect(() => {
        const first_entrance = async () => {
            let telegramUserId = window.Telegram.WebApp?.initDataUnsafe?.user?.id;
            if (telegramUserId) {
            } else {
                telegramUserId = 5191469996
            }
            window.Telegram.WebApp.expand();
            let dataClient = await getClient(telegramUserId, token)

            if (dataClient) {
                setClientId(dataClient["clientId"])
                setCountLead(parseInt(dataClient["countLead"], 10))
                await sendFormCounter(dataClient["clientId"], token, dataClient["formCounter"])
            }
        }
        first_entrance().then();
    }, [])

    const handleSubmit = async () => {
        if (await validate()) {
            let telegramUserId = window.Telegram.WebApp?.initDataUnsafe?.user?.id;
            if (telegramUserId) {
            } else {
                telegramUserId = 686171972
            }
            if (!clientId) {

                let dataClient = await getClient(telegramUserId, token)
                const clientId = dataClient["clientId"]
                setClientId(clientId)
                setCountLead(parseInt(dataClient["countLead"], 10))
                console.log("Not found clientId, get restore", clientId)
                await sendFormCounter(clientId, token, dataClient["formCounter"])

            }
            await sendCallback(telegramUserId, token)
            let incremented = countLead + 1
            setCountLead(incremented)
            await sendLead(clientId, token, incremented)
            const country_find = countries.find(country => country.code === selectedCountry);
            const dialCode = country_find ? country_find.dialCode : null;
            const orderId = await createOrder(token, clientId, budgetAmount)
            let variables = {
                "name": name,
                "phone": `${dialCode}${phoneNumber}`,
                "budget": budgetAmount,
                "status": readyToBuy,
                "country": country,
                "auto": brand,
                "city": city
            }
            await setOrderVars(token, clientId, orderId, variables)
            console.log("clientId", clientId)
            sendEmail(
                orderId,
                clientId,
                name,
                `${dialCode}${phoneNumber}`,
                brand,
                budgetAmount,
                country,
                city,
                readyToBuy)
            Swal.fire({
                title: '✅ Заявка принята!\n',
                text: 'Мы свяжемся с вами в ближайшие 1-2 рабочих дня.\n' +
                    '\n' +
                    'Проверяйте мессенджеры или ждите звонка с +79243878787',
                icon: 'info', // Тип иконки: 'success', 'error', 'warning', 'info', 'question'
                confirmButtonText: 'OK',
                timer: 5000,

            }).then((result) => {
                window.Telegram.WebApp.close();
            });
        }
    };
    return (
        <div className={"app_form"}>
            <div style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                <img
                    src={logo}
                    alt="Логотип"
                    style={{
                        width: "40px",
                        height: "auto",
                        marginRight: "10px",
                        borderRadius: "50%",
                        // marginLeft: "10px",
                    }}
                />
                <h1 style={{fontWeight: "700", fontSize: "26px", margin: "0"}}>
                    Узнать стоимость авто
                </h1>
            </div>
            <p style={{
                textAlign: "center",
                marginTop: 0,
                marginBottom: "35px",
                fontWeight: "400",
                lineHeight: "21px",
                fontSize: "11pt"
            }}>Введите данные в форму и отправьте заявку. <br/>
                <span style={{
                    backgroundColor: "transparent",
                    color: "#000000",
                    fontWeight: "400",
                    lineHeight: "21px",
                    fontSize: "11pt"
                }}>Мы бесплатно подберем несколько вариантов по вашим критериям и рассчитаем стоимость.</span>
            </p>
            <form className={"personal_form"}>
                {errors.name && <span className={"error"}>{errors.name}</span>}
                <input className={"input_name"}
                       type="text"
                       inputMode="text"  // Альтернативный вариант
                       placeholder="Ваше имя"
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               e.target.blur();  // Закрыть клавиатуру при нажатии Enter
                           }
                       }}


                />
                {errors.phoneNumber &&
                    <span className={"error"} style={{marginBottom: "10px"}}>{errors.phoneNumber}</span>}
                <div className="phone-input">
                    <select className={"country-select"}
                            value={selectedCountry}
                            onChange={(e) => {
                                const newCountryCode = e.target.value;
                                setSelectedCountry(newCountryCode);
                                const selectedCountryData = countries.find((country) => country.code === newCountryCode);
                                setMaxPhoneLength(selectedCountryData.maxLength);
                            }}>
                        {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                                {selectedCountry === country.code
                                    ? `${country.code} ${country.dialCode}`
                                    : `${country.code} ${country.name} ${country.dialCode}`} {/* Полное название в списке */}
                            </option>
                        ))}
                    </select>
                    <input className={"phone-number-input"}
                           type="text"
                           value={phoneNumber}
                           onChange={(e) => {
                               const inputValue = e.target.value;
                               if (/^\d*$/.test(inputValue)) {
                                   setPhoneNumber(inputValue);
                               }
                           }}
                           placeholder={countryPlaceholders[selectedCountry]}
                           maxLength={maxPhoneLength}
                           onKeyPress={(e) => {
                               if (e.key === 'Enter') {
                                   e.target.blur();  // Закрыть клавиатуру при нажатии Enter
                               }
                           }}

                    />
                </div>
                <div className={"input_budget"}>
                            <span className={"input_budget_span"}>
                                Выберите бюджет на покупку авто. Это поможет направить вас в нужный отдел, чтобы вы получили лучшее
                                обслуживание.
                            </span>
                    {errors.budget && <span className={"error"}>{errors.budget}</span>}
                    <label className={"radio-container"}>
                        <input
                            type="radio"
                            name="budget"
                            value="До 7 500 000"
                            checked={budget === "До 7 500 000"}
                            onChange={(e) => {
                                setBudget(e.target.value)
                                setBudgetAmount("")
                            }}
                        />
                        <span>До 7 500 000 ₽</span>
                    </label>
                    <label className={"radio-container"}>
                        <input
                            type="radio"
                            name="budget"
                            value="От 7 500 000"
                            checked={budget === "От 7 500 000"}
                            onChange={(e) => {
                                setBudget(e.target.value)
                                setBudgetAmount("7500000")
                            }}
                        />
                        <span>От 7 500 000 ₽</span>
                    </label>

                </div>
            </form>
            {budget === "До 7 500 000" && (
                <div className={"add_form"}>
                    <label>Готовы к покупке или присматриваетесь?
                        <select className={"field_add_form_select"}
                                value={readyToBuy}
                                onChange={(e) => setReadyToBuy(e.target.value)}>
                            <option value="Готов к покупке">Готов к покупке</option>
                            <option value="Присматриваюсь">Присматриваюсь</option>
                        </select>
                    </label>

                    <label>Выберите страну импорта
                        <select className={"field_add_form_select"}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}>
                            <option value="Корея">Корея</option>
                            <option value="Китай">Китай</option>
                            <option value="Япония">Япония</option>
                            <option value="Затрудняюсь ответить">Затрудняюсь ответить</option>
                        </select>
                    </label>

                    <label>Напишите марку и модель авто
                        {errors.brand && <span className={"error"}>{errors.brand}</span>}
                        <input className={"field_add_form"}
                               type="text"
                               inputMode="text"  // Альтернативный вариант
                               placeholder="Zeekr 001"
                               value={brand}
                               onChange={(e) => setBrand(e.target.value)}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       e.target.blur();  // Закрыть клавиатуру при нажатии Enter
                                   }
                               }}
                               onFocus={(e) => {
                                   e.target.scrollIntoView({behavior: 'smooth', block: 'center'});
                               }}
                        />
                    </label>

                    <label>Укажите ваш бюджет в рублях
                        {errors.budgetAmount && <span className={"error"}>{errors.budgetAmount}</span>}
                        <input className={"field_add_form"}
                               type="text"
                               inputMode="text"  // Альтернативный вариант
                               placeholder="6 000 000 ₽"
                               value={budgetAmount}
                               onChange={(e) => {
                                   const inputValue = e.target.value;
                                   if (/^\d*$/.test(inputValue)) {
                                       setBudgetAmount(inputValue);
                                   }
                               }}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       e.target.blur();  // Закрыть клавиатуру при нажатии Enter
                                   }
                               }}
                               onFocus={(e) => {
                                   e.target.scrollIntoView({behavior: 'smooth', block: 'center'});
                               }}


                        />
                    </label>

                    <label>Введите город доставки
                        <input className={"field_add_form"}
                               type="text"
                               inputMode="text"  // Альтернативный вариант
                               placeholder="Москва"
                               value={city}
                               onChange={(e) => setCity(e.target.value)}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       e.target.blur();  // Закрыть клавиатуру при нажатии Enter
                                   }
                               }}
                               onFocus={(e) => {
                                   e.target.scrollIntoView({behavior: 'smooth', block: 'end'});
                               }}
                        />
                    </label>

                </div>
            )}
            <button className={"main-btn"} onClick={handleSubmit}>
                Отправить
            </button>
            <p
                onFocus={(e) => {
                    e.target.scrollIntoView({behavior: 'smooth', block: 'start'});
                }}
                style={{marginBottom: '300px'}}
            />
        </div>
    )
};

export default BudgetForm;
