const createOrder = async (token, clientId, budget) => {
    const url = `https://chatter.salebot.pro/api/${token}/create_order`;

    const bodyData = {
        client_id: clientId,
        name: "Название сделки",
        description: "Описание сделки",
        budget: budget,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(bodyData),
        });

        if (response.ok) {
            const data = await response.json();
            return data.order_id;
        } else {
            console.error("Ошибка запроса:", response.status);
        }
    } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
    }
};

export default createOrder;